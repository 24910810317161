import { TZDate } from "@date-fns/tz";

/**
 * 日本標準時での現在時刻を取得する。
 * @returns {Date} 現在時刻（日本標準時）
 */
export function getCurrentDateTimeOnJst() {
  const currentUtc = new Date().toISOString();
  const dateObj = new TZDate(currentUtc, "Asia/Tokyo");
  return dateObj;
}

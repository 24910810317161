<script>
  import Button, { Label } from "@smui/button";
  import Dialog, { Content, Header, Title } from "@smui/dialog";
  import IconButton from "@smui/icon-button";
  import Textfield from "@smui/textfield";
  import HelperText from "@smui/textfield/helper-text";
  import { createEventDispatcher, getContext } from "svelte";

  import { CONTEXT_KEY_USER } from "~/libs/constants";
  import { toast } from "~/libs/toast";

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  const dispatch = createEventDispatcher();

  export let trackingNumber;

  /** @type {boolean} ESCキーやblurでダイアログを閉じないようにするか否か */
  let mandatory = true;

  /**
   * @callback onDialogClosedCallback
   * @param {CustomEvent<{ action: "ok" | "cancel" | "close" }>} event
   */
  /**
   * @type {onDialogClosedCallback}
   */
  let onDialogClosedHandler = () => {};

  /** @type {boolean} ダイアログの開閉フラグ */
  let dialogOpened = false;

  let deliveryList = userContext.deliveryList;

  /** @type {number} */
  let index;
  for (let i = 0; i < deliveryList.length; i++) {
    if (trackingNumber === deliveryList[i].trackingNumber) {
      index = i;
      break;
    }
  }

  /** @type {string} */
  let memoValue = deliveryList[index].personalMemo
    ? deliveryList[index].personalMemo
    : "";

  /**
   * ダイアログを開く。
   */
  export function openDialog() {
    memoValue = deliveryList[index].personalMemo
      ? deliveryList[index].personalMemo
      : "";
    dialogOpened = true;
  }

  function closeDialog() {
    dialogOpened = false;
  }

  function registMemo() {
    deliveryList[index].personalMemo = memoValue;
    userContext.deliveryList = deliveryList;
    userContext.store();
    dispatch("update", { personalMemo: memoValue });
    closeDialog();
    toast.info("個人メモを登録しました");
  }
</script>

{#if dialogOpened}
  <div class="personalMemoDialog">
    <Dialog
      bind:open={dialogOpened}
      scrimClickAction={mandatory ? "" : "close"}
      escapeKeyAction={mandatory ? "" : "close"}
      on:SMUIDialog:closed={onDialogClosedHandler}
      aria-labelledby="preset-dialog-title"
      aria-describedby="preset-dialog-content"
    >
      <Header>
        <Title id="preset-dialog-title">個人メモの登録</Title>
        <IconButton
          action="close"
          ripple={false}
          class="material-icons"
          style="position: absolute; top: 5px; right: 5px;">close</IconButton
        >
      </Header>
      <Content id="preset-dialog-content">
        <Textfield
          style="width: 100%; height: 10rem;"
          textarea
          bind:value={memoValue}
        >
          <HelperText persistent slot="helper"
            >荷物を持ち出してから、配達完了または持ち戻るまで有効な自分専用のメモ欄です。</HelperText
          >
        </Textfield>
        <div class="buttonArea">
          <Button
            class="registerButton"
            color="secondary"
            variant="unelevated"
            on:click={() => {
              registMemo();
            }}
          >
            <Label>登録する</Label>
          </Button>
        </div>
      </Content>
    </Dialog>
  </div>
{/if}

<style lang="scss">
  .personalMemoDialog {
    :global(.mdc-dialog .mdc-dialog__surface) {
      width: calc(100vw - 32px);
    }

    :global(.mdc-dialog__header) {
      text-align: center;
    }
    .buttonArea {
      margin-top: 15px;

      :global(.registerButton) {
        width: 100%;
      }
    }
  }
</style>

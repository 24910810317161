<script>
  import Button, { Label } from "@smui/button";
  import Checkbox from "@smui/checkbox";
  import FormField from "@smui/form-field";
  import Select, { Option } from "@smui/select";
  import { format as formatDate } from "date-fns";
  import { ja as localeJa } from "date-fns/locale";
  import { getContext } from "svelte";

  import ConfirmDialog from "~/components/ConfirmDialog.svelte";
  import {
    ABSENCE,
    CHOICES_OF_TIME,
    CONTEXT_KEY_USER,
    ConfirmDialogTypes,
    RedeliveryDateSpecifyMethod,
  } from "~/libs/constants";
  import { deliveryTarget } from "~/libs/stores";
  import { getCurrentDateTimeOnJst } from "~/libs/timeUtil";
  import { formatTrackingNumber } from "~/libs/trackingNumberUtils";

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  /** @type {Array<string>} */
  const DEFAULT_TIME_FRAME = ["0912", "1215", "1518", "1821"];

  /** @type {number} 配達不可の場合の理由区分 */
  export let extraEventType;

  /** @type {boolean} 再送の場合にtrue */
  export let resendFlag = false;

  /** @type {ConfirmDialog} 確認ダイアログコンポーネントのインスタンス */
  let dialog;

  /** @type {boolean} 再配達日時を電話で調整する場合にtrue */
  let adjustByTel = resendFlag ? true : false;

  /** @type {Date} 電話で調整した再配達の日付 */
  let adjustedDate;

  /** @type {Date} 本日の日付 */
  const today = getCurrentDateTimeOnJst();

  /**
   * 「時間帯を指定しない」にチェックが入っているかどうかを保持する変数
   * @type {boolean}
   */
  let noTimeSpecifiedChecked = false;

  /** @type {string} 電話で調整した再配達の時間帯の開始時間 */
  let startOfAdjustedTime = "00";

  /** @type {string} 電話で調整した再配達の時間帯の終了時間 */
  let endOfAdjustedTime = "24";

  /** @type {boolean} 入力内容確認のチェック */
  let confirmChecked = false;

  /** @type {import("~/libs/commonTypes").DeliveryPackage} 配達対象の荷物情報 */
  let deliveryPackage = $deliveryTarget;

  /** @type {string} */
  let shipperName;
  for (let i = 0; i < userContext.deliveryList.length; i++) {
    if (
      userContext.deliveryList[i].trackingNumber ===
      deliveryPackage.trackingNumber
    ) {
      shipperName = userContext.deliveryList[i].shipperName;

      break;
    }
  }

  /**
   * ダイアログを開く
   */
  export function openDialog() {
    dialog.openDialog();
  }

  console.log(deliveryPackage);
</script>

<div class="absentNotificationDialog">
  <ConfirmDialog
    bind:this={dialog}
    type={ConfirmDialogTypes.CLOSE}
    mandatory={true}
  >
    <svelte:fragment slot="title">
      {#if resendFlag}
        再配達日時登録
      {:else}
        不在・置き配不可登録
      {/if}</svelte:fragment
    >
    <svelte:fragment slot="content">
      <div class="pageWrapper">
        {#if !resendFlag}
          <section>
            <h3>1. 再配達の調整方法を選択</h3>
            <div class="sectionContents">
              <div class="segmentButtonArea">
                <label class="segmentButtonWrapper">
                  <input
                    type="radio"
                    name="adjustNecessity"
                    value={false}
                    bind:group={adjustByTel}
                    on:click={() => {
                      confirmChecked = false;
                    }}
                  />
                  <div class="segmentButton">依頼を待つ</div>
                </label>
                <label class="segmentButtonWrapper">
                  <input
                    type="radio"
                    name="adjustNecessity"
                    value={true}
                    bind:group={adjustByTel}
                    on:click={() => {
                      confirmChecked = false;
                    }}
                  />
                  <div class="segmentButton">電話をかける</div>
                </label>
              </div>
              <p class="description">
                {#if !adjustByTel}
                  WEB・電話による荷受人からの再配達依頼を待ちます。
                {:else}
                  荷受人に電話をかけて再配達の日程調整を行い、調整内容を登録します。
                {/if}
              </p>
            </div>
          </section>
        {/if}
        {#if adjustByTel}
          {#if !resendFlag}
            <section>
              <h3>2. 電話で調整を行う</h3>
              <div class="sectionContents">
                <button
                  class="callButton"
                  on:click={() => {
                    window.location.href = `tel:${deliveryPackage.receiverTel}`;
                  }}
                  ><span class="material-icons">call</span><span
                    >電話をかける</span
                  ></button
                >
                <p class="description">
                  電話が繋がらない場合は「荷受人の依頼を待つ」を選択ください。
                </p>
              </div>
            </section>
          {/if}
          <section>
            <h3>{!resendFlag ? "3. " : ""}調整した再配達日時を登録する</h3>
            <div class="sectionContents">
              <label class="selectLabel" for="deliveryDate">日付</label>
              <div class="dateSelect">
                <div class="dateSelect">
                  <input
                    id="deliveryDate"
                    type="date"
                    class="inputDesiredDate"
                    min={formatDate(today, "yyyy-MM-dd", {
                      locale: localeJa,
                    })}
                    bind:value={adjustedDate}
                  />
                </div>
              </div>
              <label class="selectLabel" for="deliveryTime">時間</label>
              <div class="deliveryTimeSelect">
                <Select
                  id="deliveryTime"
                  variant="outlined"
                  style="width: calc(50% - 12px);"
                  disabled={noTimeSpecifiedChecked}
                  bind:value={startOfAdjustedTime}
                >
                  {#each CHOICES_OF_TIME as time, index}
                    {#if index !== CHOICES_OF_TIME.length - 1 && (endOfAdjustedTime !== "" ? time < endOfAdjustedTime : true)}
                      <Option value={time}>{Number(time)}時</Option>
                    {/if}
                  {/each}
                </Select>
                <span>～</span>
                <Select
                  variant="outlined"
                  style="width: calc(50% - 12px);"
                  disabled={noTimeSpecifiedChecked}
                  bind:value={endOfAdjustedTime}
                >
                  {#each CHOICES_OF_TIME as time, index}
                    {#if index !== 0 && (startOfAdjustedTime !== "" ? time > startOfAdjustedTime : true)}
                      <Option value={time}>{Number(time)}時</Option>
                    {/if}
                  {/each}
                </Select>
              </div>
            </div>
            <div class="desiredTimeCheckbox">
              <FormField>
                <Checkbox bind:checked={noTimeSpecifiedChecked} />
                <span slot="label">時間帯を指定しない</span>
              </FormField>
            </div>
          </section>
        {/if}
        {#if !resendFlag}
          <section>
            <h3>{adjustByTel ? "4" : "2"}. 不在連絡票を投函する</h3>
            <div class="sectionContents">
              <p>不在連絡票に必要事項を記載のうえ投函してください。</p>
              <table class="deliveryNoticeTable">
                <tr>
                  <th>荷受人名</th>
                  <td>{deliveryPackage.receiverName}</td>
                </tr>
                <tr>
                  <th>ご依頼主名</th>
                  <td>{shipperName}</td>
                </tr>
                <tr>
                  <th>配達日時</th>
                  <td>{formatDate(new Date(), "M月d日 H時m分")}</td>
                </tr>
                <tr>
                  <th>送り状番号</th>
                  <td>{formatTrackingNumber(deliveryPackage.trackingNumber)}</td
                  >
                </tr>
                <tr>
                  <th>ご連絡事項</th>
                  <td>
                    {#if adjustByTel}
                      <div>
                        ■再配達の調整結果
                        <div class="ml-5">
                          {#if adjustedDate}{formatDate(
                              adjustedDate,
                              "M/d(E)",
                              {
                                locale: localeJa,
                              },
                            )}{/if}
                          {#if !adjustedDate && startOfAdjustedTime === "00" && endOfAdjustedTime === "24" && !noTimeSpecifiedChecked}&nbsp;
                          {:else if noTimeSpecifiedChecked}
                            時間指定なし
                          {:else if startOfAdjustedTime || endOfAdjustedTime}
                            {#if startOfAdjustedTime}{Number(
                                startOfAdjustedTime,
                              )}時{/if}～{#if endOfAdjustedTime}{Number(
                                endOfAdjustedTime,
                              )}時{/if}
                          {/if}
                        </div>
                      </div>
                    {/if}
                    {#if Number.isInteger(deliveryPackage.cashOnDeliveryAmount)}
                      <div>
                        ■代引き
                        <div class="ml-5">
                          あり（{deliveryPackage.cashOnDeliveryAmount.toLocaleString()}円）
                        </div>
                      </div>
                    {/if}
                  </td>
                </tr>
                {#if !adjustByTel}
                  <tr>
                    <th>宅配ドライバー直通電話</th>
                    <td>荷受人から直接電話を受けたい場合は記載してください</td>
                  </tr>
                {/if}
              </table>
            </div>
          </section>
        {/if}
        <div class="confirmArea">
          <p>
            {#if !resendFlag}
              「不在・置き配不可」として登録します。誤りがないか確認してください。
            {:else if resendFlag}
              本内容で再配達日時を登録します。内容に誤りがないか確認してください。
            {/if}
          </p>
          <FormField>
            <Checkbox
              bind:checked={confirmChecked}
              disabled={adjustByTel && !adjustedDate ? true : false}
            />
            <span slot="label">確認した</span>
          </FormField>
        </div>
        <div class="buttonArea">
          <Button
            color="secondary"
            variant="unelevated"
            disabled={!confirmChecked}
            on:click={() => {
              /** @type {import("~/libs/commonTypes").RedeliveryContext} */
              const redeliveryContext = {
                redeliveryDatetimeSpecMethod:
                  RedeliveryDateSpecifyMethod.DESIRED_DATE_AND_TIME,
                timeFramePreset: DEFAULT_TIME_FRAME,
                redeliveryUnavailability: null,
                adjustedRedeliveryDatetime: null,
                notificationResend: resendFlag,
              };
              if (adjustByTel) {
                if (!noTimeSpecifiedChecked) {
                  redeliveryContext.adjustedRedeliveryDatetime = {
                    date: formatDate(adjustedDate, "yyyy-MM-dd", {
                      locale: localeJa,
                    }),
                    timeFrame: startOfAdjustedTime + endOfAdjustedTime,
                  };
                } else {
                  redeliveryContext.adjustedRedeliveryDatetime = {
                    date: formatDate(adjustedDate, "yyyy-MM-dd", {
                      locale: localeJa,
                    }),
                    timeFrame: null,
                  };
                }
              }
              deliveryPackage.redeliveryContext = redeliveryContext;
              deliveryTarget.set(deliveryPackage);
              extraEventType = ABSENCE;
            }}
          >
            <Label>登録する</Label>
          </Button>
        </div>
      </div>
    </svelte:fragment>
  </ConfirmDialog>
</div>

<style lang="scss">
  .absentNotificationDialog {
    :global(.mdc-dialog__title) {
      text-align: center;
    }
    :global(.mdc-dialog__content) {
      padding: 0px 18px 20px 18px;
    }
  }
  section {
    margin-top: 20px;
  }
  h3 {
    color: #000;
    font-size: 16px;
    font-weight: bold;
  }
  .sectionContents {
    color: #000;
    margin: 8px 5px 10px;

    :global(.mdc-select .mdc-select__anchor) {
      width: 100%;
    }
    :global(
        .mdc-select .mdc-select__menu .mdc-deprecated-list-item[hidden="true"]
      ) {
      display: none;
    }
    :global(.mdc-select .mdc-select__menu.mdc-menu-surface) {
      max-height: 200px !important;
    }
  }
  .segmentButtonArea {
    display: flex;
    margin-top: 8px;
  }
  .segmentButtonWrapper {
    display: block;
    width: calc(100% / 2);
  }
  .segmentButtonWrapper input[type="radio"] {
    display: none;
  }
  .segmentButton {
    color: #018786;
    border: 1px solid #018786;
    padding: 6px 0;
    text-align: center;
    width: 100%;
  }
  .segmentButtonWrapper:first-of-type .segmentButton {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  .segmentButtonWrapper:last-of-type .segmentButton {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  input[type="radio"]:checked + .segmentButton {
    background-color: #018786;
    color: #fff;
    font-weight: bold;
  }
  input[type="radio"]:disabled + .segmentButton {
    background-color: rgba(0, 0, 0, 0.12);
    border-color: rgba(0, 0, 0, 0);
    color: rgba(0, 0, 0, 0.38);
  }
  .description {
    margin-top: 8px;
  }

  .callButton {
    padding: 5px 0;
    background-color: #fff;
    border: 1px solid #018786;
    border-radius: 4px;
    width: 100%;

    span {
      font-size: 15px;
      color: #018786;

      &.material-icons {
        font-size: 20px;
        margin-right: 3px;
        vertical-align: middle;
      }
    }
  }
  .selectLabel {
    display: block;
    font-weight: bold;
    margin-top: 5px;
  }
  .dateSelect {
    :global(.mdc-select) {
      width: 100%;
    }
  }
  .inputDesiredDate {
    box-sizing: border-box;
    width: 100%;
    height: 56px;
    padding: 0 12px;
    border: 1px solid #999;
    border-radius: 5px;
  }
  .deliveryTimeSelect {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
  }
  .deliveryTimeSelect > span {
    display: inline-block;
    width: 2em;
  }
  .confirmArea {
    color: #000;
    margin-top: 30px;

    :global(
        .mdc-checkbox
          .mdc-checkbox__native-control[disabled]:not(:checked):not(
            :indeterminate
          ):not([data-indeterminate="true"])
          ~ .mdc-checkbox__background
      ) {
      background-color: rgba(0, 0, 0, 0.1);
      border-color: rgba(0, 0, 0, 0.2);
    }
  }
  .deliveryNoticeTable {
    border-top: 1px solid #333;
    border-left: 1px solid #333;
    margin-top: 10px;
    width: 100%;
  }
  .deliveryNoticeTable th {
    background-color: #2ab47410;
    border-right: 1px solid #333;
    border-bottom: 1px solid #333;
    width: 110px;
    padding: 5px 8px;
  }
  .deliveryNoticeTable td {
    border-right: 1px solid #333;
    border-bottom: 1px solid #333;
    padding: 5px 8px;
  }
  .deliveryNoticeTable .ml-5 {
    margin-left: 5px;
  }
  .buttonArea {
    margin-top: 20px;

    :global(.mdc-button) {
      width: 100%;
    }
    :global(.mdc-button:not(:first-of-type)) {
      margin-top: 20px;
    }
  }
</style>
